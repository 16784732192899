import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { notification, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { ClientForm } from '../../components/forms';

const EditClient = (props) => {
  const currentuser = useContext(UserContext);
  const [client, fetchClient] = useAxios('', null, currentuser.data.token, 'get');
  const history = useHistory();
  const { clientId } = props.match.params;

  useEffect(() => {
    if (clientId) {
      fetchClient(`${SERVER_URL}/clients/${clientId}`, []);
    }
  }, [clientId, fetchClient]);

  const updateClient = async (data) => {
    try {
      await Axios.put(`${SERVER_URL}/clients/${clientId}`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Client is updated.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/clients');
    } catch (err) {
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const createClient = async (data) => {
    try {
      await Axios.post(`${SERVER_URL}/clients`, { ...data }, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Client created.',
        placement: 'bottomRight',
      });
      history.push('/admin');
      history.push('/admin/clients');
    } catch (err) {
      notification.error({
        message: err.response.data && err.response.data.message ? err.response.data.message : 'Problem with creating client. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/clients'>
          <Button type='primary'>All clients</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {clientId && client.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {clientId && !client.isLoading && !client.isError && client.data && client.data.email && (
          <ClientForm data={client.data} updateHandler={updateClient} token={currentuser.data.token} />
        )}
        {clientId && !client.isLoading && client.isError && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {!clientId && client && !client.data && <ClientForm data={null} createHandler={createClient} token={currentuser.data.token} />}
      </div>
    </div>
  );
};

export default EditClient;
