import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, notification, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { GalleryTable } from '../../components/tables';

const TABLE_COLUMN_KEYS = [
  '_id',
  '__v',
  'key',
  'updatedAt',
  'uploadedBy',
  'url',
  'forMobile',
  'localPath',
];

const Gallery = (props) => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [galleryForDelete, setGalleryForDelete] = useState([]);
  const [confirmDeleteVisible, setConfirmDeleteVisible] = useState(false);
  const [images, fetchImages] = useAxios('', null, currentuser.data.token, 'get');
  const { id } = props.match.params;

  useEffect(() => {
    fetchImages(`${SERVER_URL}/images`);
  }, [id, fetchImages]);

  let columnKeys;
  if (images && images.data && images.data.length > 0) {
    const keys = Object.keys(images.data[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (images && images.data && images.data.length > 0) {
    tableData = images.data.map((item) => {
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  const rowSelectionTable = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length > 0) {
        let idsForRemove = [];
        selectedRows.filter((item) => idsForRemove.push(item._id));
        setGalleryForDelete(idsForRemove);
      } else {
        setGalleryForDelete([]);
      }
    },
    getCheckboxProps: (record) => ({
      name: record.name,
    }),
  };

  // DELETE IMAGES AND REFS FROM DB
  const galeryDelete = async () => {
    if (galleryForDelete.length > 0) {
      const selectedImages = { images: galleryForDelete };

      try {
        await Axios.post(`${SERVER_URL}/images-delete`, selectedImages, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });
        notification.success({
          message: 'Images deleted.',
          placement: 'bottomRight',
        });
        history.push('/admin');
        history.push('/admin/gallery');
      } catch (err) {
        notification.error({
          message: 'Problem with deleting images. Please try later.',
          placement: 'bottomRight',
        });
      }
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block'>
        <Link to='/admin/gallery/new-content'>
          <Button type='primary'>Add new content</Button>
        </Link>
        <Button
          style={{ marginLeft: '8px' }}
          onClick={() => setConfirmDeleteVisible(true)}
          type='primary'
        >
          Delete selected
        </Button>
      </div>

      <div className='content'>
        {images && images.isLoading && (
          <LoadingOutlined
            spin
            style={{
              fontSize: '3rem',
              marginTop: '5rem',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        )}

        {images && !images.isLoading && images.isError && (
          <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>
        )}

        {images &&
          !images.isLoading &&
          !images.isError &&
          images.data &&
          images.data.length > 0 && (
            <GalleryTable
              data={tableData}
              columnKeys={columnKeys}
              rowSelectionTable={rowSelectionTable}
              setConfirmDeleteVisible={setConfirmDeleteVisible}
              title='View Gallery'
            />
          )}

        {images && images.data && !images.isLoading && images.data.length === 0 && (
          <h2 style={{ marginTop: '3rem', textAlign: 'center' }}>NO IMAGES</h2>
        )}

        <Modal
          title='DELETE IMAGES'
          visible={confirmDeleteVisible}
          onOk={galeryDelete}
          onCancel={Modal.destroyAll()}
          okText='Yes'
          cancelText='No'
        >
          <p>Are You sure You want to delete selected images?</p>
        </Modal>
      </div>
    </div>
  );
};

export default Gallery;
