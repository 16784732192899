import React, { useEffect, useState } from 'react';
// import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL, FRONTEND_URL } from '../../config';
import { Form, Input, Button, Card, Upload, notification, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

const tailLayout = {
  wrapperCol: { offset: 8, span: 24 },
};

const initialValues = {
  email: undefined,
  title: undefined,
  firstName: undefined,
  lastName: undefined,
  company: undefined,
  phone: undefined,
  files: [],
  fileUrl: '',
};

const ClientForm = ({ data, updateHandler, createHandler, token }) => {
  // const history = useHistory();
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  let client = data ? { ...data } : initialValues;
  const isNew = data ? false : true;

  useEffect(() => {
    if (data?.files?.length > 0) {
      const name = data.files[0].split('/');
      setFileList([{ uid: -1, name: name[name.length - 1], path: data.files[0] }]);
    }
  }, [data]);

  const onFinish = (values, isNew) => {
    if (isNew) {
      createHandler(values);
    } else {
      if (fileList.length === 0) values.files = [];
      updateHandler(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file) => {
    const isAllowedFormat = ['application/zip'].includes(file.type);
    if (!isAllowedFormat) {
      notification.error({
        message: 'You can only upload ZIP file!',
        placement: 'bottomRight',
      });
    }
    // const isAllowedSize = file.size / 1024 / 1024 < 5;
    // if (!isAllowedSize) {
    //   notification.error({
    //     message: 'Image must smaller than 5MB!',
    //     placement: 'bottomRight',
    //   });
    // }
    return isAllowedFormat;
  };

  const onFileChange = async ({ file }) => {
    if (file.status === 'done') {
      if (file.response && file.response.file) {
        const fileNameSplit = file.response.file.split('/');
        const fileName = fileNameSplit[fileNameSplit.length - 1];
        const fileUrl = `${data._id}?file=${fileName}`;
        form.setFieldsValue({ files: [file.response.file], fileUrl: `${FRONTEND_URL}/${fileUrl}` });
        setFileList([file]);
        message.success(`${file.response.file.name} file uploaded successfully.`);
      }
    } else if (file.status === 'error') {
      message.error(`${file.response.file.name} file upload failed.`);
    }
  };

  const onFileRemove = async (file) => {
    const fileName = file.path;
    try {
      const result = await Axios.delete(`${SERVER_URL}/upload-local-file?file=${fileName}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` },
      });

      if (result?.status === 200) {
        form.setFieldsValue({ files: [], fileUrl: '' });
        setFileList([]);
        message.success(`${fileName} file removed.`);
        return true;
      }
      return false;
    } catch (error) {
      console.log('file remove error =', error);
      message.error(`${fileName} file NOT removed.`);
      return false;
    }
  };

  return (
    <div>
      <div className='dashboard'>
        <div className='card-wrapper'>
          <Card title={`${isNew ? 'NEW' : 'EDIT'} CLIENT`} bordered={false}>
            <Form name='basic' initialValues={client} onFinish={(values) => onFinish(values, isNew)} onFinishFailed={onFinishFailed} layout='vertical' form={form}>
              <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please enter email!' }]}>
                <Input placeholder='Email' />
              </Form.Item>
              <Form.Item label='Title' name='title'>
                <Input placeholder='Title' />
              </Form.Item>
              <Form.Item label='First name' name='firstName' rules={[{ required: true, message: 'Please enter first name!' }]}>
                <Input placeholder='First name' />
              </Form.Item>
              <Form.Item label='Last name' name='lastName' rules={[{ required: true, message: 'Please enter last name!' }]}>
                <Input placeholder='Last name' />
              </Form.Item>
              <Form.Item label='Company' name='company'>
                <Input placeholder='Company' />
              </Form.Item>
              <Form.Item label='Phone' name='phone'>
                <Input placeholder='Phone' />
              </Form.Item>

              {data?._id && (
                <>
                  <Form.Item label='File download link' name='fileUrl'>
                    <Input placeholder='File link' disabled style={{ cursor: 'text' }} />
                  </Form.Item>

                  <Form.Item label='Files' name='files'>
                    <Upload
                      action={`${SERVER_URL}/upload-local-file`}
                      accept='.zip, .tar, .tar.gz, .rar'
                      name='file'
                      fileList={fileList}
                      maxCount={1}
                      getValueFromEvent={normFile}
                      headers={{
                        client: data._id.toString(),
                        Authorization: `Bearer ${token}`,
                        path: 'public/clients',
                      }}
                      beforeUpload={beforeUpload}
                      onChange={onFileChange}
                      onRemove={onFileRemove}
                    >
                      <Button icon={<UploadOutlined />} disabled={fileList.length === 1}>
                        Upload
                      </Button>
                    </Upload>
                  </Form.Item>
                </>
              )}
              <Form.Item {...tailLayout}>
                <Button type='primary' htmlType='submit' style={{ float: 'right' }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ClientForm;
