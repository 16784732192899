import React from "react";
import { Link } from "react-router-dom";
import { Layout, Menu } from "antd";
import { ROUTES } from "../../config";

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavMenu = (props) => {
	const { isCollapsed, setCollapsed } = props;

	return (
		<Sider
			breakpoint='lg'
			onBreakpoint={(broken) => setCollapsed(broken)}
			trigger={null}
			collapsible
			collapsed={isCollapsed}
		>
			<Link to='/admin'>
				<div className='logo'>
					{/* <img src='/DV770051.jpg' alt=' api' /> */}
					<p style={{ color: "white" }}>Damir Vujković</p>
				</div>
			</Link>
			<Menu
				theme='dark'
				mode='inline'
				defaultSelectedKeys={[window.location.pathname]}
			>
				{ROUTES.map((route) => (
					<SubMenu
						key={route.label.toLowerCase()}
						title={
							isCollapsed ? (
								<route.icon />
							) : (
								<span>
									<route.icon />
									{route.label}
								</span>
							)
						}
					>
						{route.children
							.filter((item) => item.showInMenu)
							.map((item) => (
								<Menu.Item key={item.path}>
									<Link to={item.path}>{item.label}</Link>
								</Menu.Item>
							))}
					</SubMenu>
				))}
			</Menu>
		</Sider>
	);
};
export default NavMenu;
