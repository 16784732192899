import React, { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { UserContext } from "../../App";
import useAxios from "../../hooks/useAxios";
import { SERVER_URL } from "../../config";
import AboutForm from "../../components/forms/AboutForm";

const Aboutpage = () => {
	const history = useHistory();
	const currentuser = useContext(UserContext);
	const [homepage, fetchHomepage] = useAxios(
		"",
		{},
		currentuser.data.token,
		"get"
	);

	useEffect(() => {
		fetchHomepage(`${SERVER_URL}/about`, {});
	}, [fetchHomepage]);

	const onSubmit = async (formData) => {
		console.log("dash pages about", formData);
		try {
			await Axios.put(`${SERVER_URL}/about`, formData, {
				withCredentials: false,
				headers: { Authorization: `Bearer ${currentuser.data.token}` },
			});

			notification.success({
				message: "about page updated",
				placement: "bottomRight",
			});
			history.push("/admin");
			history.push("/admin/about");
		} catch (error) {
			const msg = error.response
				? error.response.data.message
				: error.message
				? error.message
				: error;
			console.log(msg);
			notification.error({
				message: msg,
				placement: "bottomRight",
			});
		}
	};

	return (
		<div style={{ padding: "8px" }}>
			<div>
				{homepage.isLoading && (
					<div style={{ textAlign: "center" }}>
						<LoadingOutlined
							spin
							style={{ fontSize: "3rem", marginTop: "5rem" }}
						/>
					</div>
				)}

				{!homepage.isLoading &&
					!homepage.isError &&
					homepage.data &&
					currentuser.language && (
						<AboutForm
							homepage={homepage.data}
							language={currentuser.language}
							onSubmit={onSubmit}
						/>
					)}

				{!homepage.isLoading && homepage.isError && (
					<h2>Error fetching data :(</h2>
				)}
			</div>
		</div>
	);
};

export default Aboutpage;
