import React, { useEffect, useContext } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { notification, Button } from 'antd';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { Table } from '../../components/tables';

import { SERVER_URL } from '../../config';

const TABLE_COLUMN_KEYS = ['_id', '__v', 'phone', 'createdBy', 'modifiedBy', 'files', 'fileUrl'];

const Clients = () => {
  const currentuser = useContext(UserContext);
  const [clients, fetchClients] = useAxios('', [], currentuser.data.token, 'get');

  useEffect(() => {
    fetchClients(`${SERVER_URL}/clients`, []);
  }, [fetchClients]);

  const deleteClientHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/clients/${id}`, { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } });
      notification.success({
        message: 'Client deleted.',
        placement: 'bottomRight',
      });
      setTimeout(() => {
        window.location.reload();
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  let columnKeys;
  if (clients.data.items && clients.data.items.length > 0) {
    const keys = Object.keys(clients.data.items[0]);
    columnKeys = keys.filter((k) => !TABLE_COLUMN_KEYS.includes(k));
  }

  let tableData = [];
  if (clients.data.items && clients.data.items.length > 0) {
    tableData = clients.data.items.map((item) => {
      if (Array.isArray(item.role)) item.role = item.role.join(', ');
      item.createdAt = new Date(item.createdAt).toLocaleString();
      item.updatedAt = new Date(item.updatedAt).toLocaleString();
      return item;
    });
  }

  return (
    <div style={{ padding: '8px' }}>
      <div className='actions-block'>
        <Link to='/admin/new-client'>
          <Button type='primary'>New client</Button>
        </Link>
      </div>

      <div style={{ textAlign: 'center' }}>
        {clients.isLoading && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {!clients.isLoading && clients.data.items && clients.data.items.length > 0 && (
          <Table data={tableData} deleteHandler={deleteClientHandler} columnKeys={columnKeys} title='Client' editPath='/admin/edit-client/' />
        )}
        {!clients.isLoading && clients.data.items && clients.data.items.length === 0 && <h2>NO DATA</h2>}
      </div>
    </div>
  );
};

export default Clients;
